import React, { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useAuthUser } from "store/reducers/auth";
import { ON_BOARDING_STATUS, REAL_STATUS } from "_common/constants";
import { updateOnboardingStatus } from "../services/auth";

const RedirectProjectContext = createContext();

export default function RedirectProjectProvider({ children }) {
  const history = useHistory();

  const authUser = useAuthUser();

  const redirectUser = () => {
    let path = "";

    switch (authUser?.on_boarding_status) {
      case ON_BOARDING_STATUS.EXPERTISE:
        path = `/expertise`;
        break;
      case ON_BOARDING_STATUS.INFO:
        path = `/recruitment/${authUser.id}`;
        break;
      case ON_BOARDING_STATUS.CHAT:
        path = `/recruitment/${authUser.id}/onboarding-freelancer`;
        break;
      case ON_BOARDING_STATUS.ONBOARDING_FREELANCER:
        path = `/recruitment/${authUser.id}/onboarding-freelancer`;
        break;
      case ON_BOARDING_STATUS.TEST:
        localStorage.removeItem("callTestAgain");
        path = `/recruitment/${authUser.id}/test`;
        break;
      case ON_BOARDING_STATUS.COMPLETE:
      case ON_BOARDING_STATUS.RELEASE:
        path = `/recruitment/${authUser.id}/home`;
        break;
      default:
        path = `/welcome`;
        break;
    }

    if (authUser?.on_boarding_status === ON_BOARDING_STATUS.ONBOARDING_FREELANCER
        && authUser?.choice === 'freelancer'
        && ([ REAL_STATUS.SCHEDULED_INTERVIEW, REAL_STATUS.READY_TO_ACTIVATE, REAL_STATUS.ACTIVE].includes(authUser?.real_status))
      ) {
      path = `/recruitment/${authUser.id}/home`;
    }

    if (authUser?.real_status === REAL_STATUS.WAIT_INTERVIEW
        && authUser?.choice === 'vibbrante'
      ) {
      path = `/recruitment/${authUser.id}/calendar`;
    }

    if (authUser?.real_status === REAL_STATUS.TEST_IN_CORRECTION
        && authUser?.choice === 'vibbrante'
      ) {
      path = `/recruitment/${authUser.id}/my-test`;
    }

    history.push(path);
  };

  const generateOnboardingStatus = async () => updateOnboardingStatus(authUser.on_boarding_status);

  return <RedirectProjectContext.Provider value={{ redirectUser, generateOnboardingStatus }}>{children}</RedirectProjectContext.Provider>;
}

export function useRedirectUser() {
  const context = useContext(RedirectProjectContext);
  const { redirectUser, generateOnboardingStatus } = context;
  return { redirectUser, generateOnboardingStatus };
}
