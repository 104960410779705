export default {
  background: {
    default: "#eaeef2"
  },
  primary: {
    main: "#458B23",
    contrastText: "#fff"
  },
  secondary: {
    main: "#8bc53f"
  },
  error: {
    main: "#e87e05"
  },
  navy70: "#02284B"
};
